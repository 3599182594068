<template>
  <div class="login">
    <PageNav title="易备登录注册" />
    <div class="login-wrap">
      <van-form @submit="onLogin" validate-trigger="onSubmit">
        <van-field
          v-model="phone"
          size="large"
          label="手机号"
          placeholder="手机号"
          autoComplete="off"
        />
        <van-field
          v-if="loginType == 0"
          size="large"
          type="password"
          v-model="password"
          label="密码"
          placeholder="密码"
          autoComplete="new-password"
        />
        <van-field
          v-if="loginType == 1"
          size="large"
          v-model="msgCode"
          type="number"
          center
          clearable
          label="验证码"
          placeholder="请输入短信验证码"
          autoComplete="new-password"
        >
          <van-button slot="button" v-if="smsSec < 60" disabled size="small" type="default" plain color="#00a0ff" native-type="button">重新获取({{smsSec}}s)</van-button>
          <van-button slot="button" v-else :disabled="!smsAvailable" size="small" type="default" plain color="#00a0ff" @click="onGetSms" native-type="button">获取验证码</van-button>
        </van-field>
        <div class="bottom">
          登录即代表同意<router-link to="/protocol">《易备用户服务使用协议》</router-link>
        </div>
        <div style="margin: 16px;">
          <van-button :disabled="!loginAvailable" round block type="info" native-type="submit">登录</van-button>
        </div>
      </van-form>
      <div class="quick-btn">
        <span v-if="loginType == 0" @click="changeLoginType">短信验证码登录</span>
        <span v-else @click="changeLoginType">账号密码登录</span>
        <span @click="onRegister">手机快速注册</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PageNav from '@/components/PageNav';
import { Form, Field, Toast } from 'vant';
import utils from '@/common/utils';
import User from '@/common/user';
import Fetch from '@/common/fetch';
import { UserLogin, GetCaptchaCode } from '@/common/interface';

Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);

export default {
  name: "Login",
  components: { PageNav },
  data() {
    return {
      phone: '',
      password: '',
      loginType: 0, // 登录方式 0密码 1短信
      smsSec: 60,
      msgCode: '',
      interval: null,
    };
  },
  computed: {
    smsAvailable: function () {
      if (!(/^1[34578]\d{9}$/.test(this.phone)) || this.phone.length < 11) {      
        return false;        
      }
      return true;
    },
    loginAvailable () {
      if (this.loginType == 0 && this.smsAvailable && this.password.length > 0) {
        return true;
      }
      if (this.loginType == 1 && this.smsAvailable && this.msgCode.length > 0) {
        return true;
      }
      return false;
    },
  },

  destroyed() {
    clearInterval(this.interval);
  },

  methods: {
    onBack() {
      this.$router.go(-1);
    },

    onGetSms () {
      this.getSmsCode();
      this.smsSec = 60;
      this.interval = setInterval(() => {
        if (this.smsSec <= 0) {
          this.smsSec = 60
          clearInterval(this.interval);
        } else {
          let newSec = this.smsSec - 1;
          if (newSec <= 0) {
            newSec = 0;
          }
          this.smsSec = newSec;
        }
      }, 1000);
    },

    getSmsCode() {
      const param = {
        phone: this.phone,
        checkType: 'LOGIN',
      };
      Fetch.post(GetCaptchaCode, param).then(() => {
      }).catch((err) => {
        Toast(err.msg);
      });
    },

    onLogin() {
      let params = {};
      if (this.loginType == 1) {
        params = {
          phone: this.phone,
          msgCode: this.msgCode,
          loginType: 1,
        };
      } else {
        params = {
          phone: this.phone,
          password: utils.md5(this.password),
          loginType: 2,
        };
      }

      Fetch.post(UserLogin, params).then((res) => {
        User.setInfo(res);
        if(this.$route.query.redirect){
          let redirect = this.$route.query.redirect;
          this.$router.replace(redirect);
        }else{
          this.$router.go(-1);
        }
      }).catch((err) => {
        const { msg } = err;
        Toast(msg);
      });
    },

    changeLoginType() {
      this.loginType = 1 ^ this.loginType;
    },

    onRegister() {
      this.$router.push({ path: '/register', query: this.$route.query })
    },
  },
}
</script>

<style lang="scss" scoped>
body {
  background: #fff;
}
.login-wrap {
  margin-top: 20px;
  .quick-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
    color: #999;
    font-size: 14px;
  }
  .bottom {
    padding: 0 16px;
    margin-top: 10px;
    font-size: 14px;
    color: #999;
  }
}
</style>
